<template>
  <div>
    <div class="screenSizeLap">
      <div class="login-main-container">
        <div class="h-100 d-flex align-items-center justify-content-center">
          <!-- do bind the style vth height if the typing test is over -->
          <div class="login-main-sub-container position-relative">
            <b-row class="m-0 w-100">
              <b-col class="p-0">
                <div
                  class="w-100 d-flex justify-content-center align-items-baseline"
                >
                  <!-- <img src="../assets/newimgs/GREYLOGO.svg" alt="" /> -->
                  <img
                    src="https://d33yrtc8qntbdn.cloudfront.net/Hire3xAssests/Logo/SVG/Hire3x_Website_Header.svg"
                    style="
                      display: block;
                      visibility: visible;
                      opacity: 1;
                      height: 40px;
                    "
                    alt=""
                  />
                </div>
              </b-col>
            </b-row>
            <b-row class="m-0 w-100">
              <b-col class="p-0">
                <div
                  class="d-flex align-items-center flex-column mt-3 text-center internetcheck-page-container"
                >
                  <embed
                    style="display: none"
                    id="pdfEmbed"
                    src="../assets/check-upload-speed.pdf"
                    width="100%"
                    height="500px"
                    type="application/pdf"
                  />
                  <div class="w-75 d-flex h-100 flex-column">
                    <h1 class="login-rightcont-headtxt ml-5">
                      Checking your Internet Speed
                    </h1>
                    <div>
                      <div
                        class="d-flex align-items-center justify-content-around flex-column mt-4"
                      >
                        <div
                          v-if="!bothFetched"
                          class="intspd-cardcontainer d-flex flex-column align-items-center p-4 w-50 h-auto"
                        >
                          <div class="i-guage position-relative mt-4 mb-4">
                            <div class="i-guage__body">
                              <div class="i-guage__fill"></div>
                              <div class="i-guage__cover">
                                <img
                                  :style="`transform: rotate(${angleValue}deg)`"
                                  class="speedometer-origin"
                                  id="speed-img"
                                  src="../assets/newimgs/speedometerArrow.svg"
                                  alt=""
                                />
                              </div>
                            </div>
                            <div
                              class="position-absolute speedometer-txt"
                              style="left: -26px; bottom: -5px"
                            >
                              0
                            </div>
                            <div
                              class="position-absolute speedometer-txt"
                              style="left: -10px; bottom: 47px"
                            >
                              10
                            </div>
                            <div
                              class="position-absolute speedometer-txt"
                              style="top: -20px; left: 26px"
                            >
                              30
                            </div>
                            <div
                              class="position-absolute speedometer-txt"
                              style="top: -37px; right: 84px"
                            >
                              50
                            </div>
                            <div
                              class="position-absolute speedometer-txt"
                              style="top: -20px; right: 18px"
                            >
                              70
                            </div>
                            <div
                              class="position-absolute speedometer-txt"
                              style="right: -18px; bottom: 46px"
                            >
                              90
                            </div>
                            <div
                              class="position-absolute speedometer-txt"
                              style="right: -52px; bottom: -5px"
                            >
                              100+
                            </div>
                          </div>

                          <div
                            class="intspd-speed-container d-flex flex-column align-items-center p-4 mt-5"
                          >
                            <div class="d-flex">
                              <div class="mr-5">
                                <div class="intcheck-mbps-head">
                                  Mbps Download
                                </div>
                                <div class="intcheck-mbps-number">
                                  {{ downloadSpeed }}
                                </div>
                              </div>
                              <div>
                                <div class="intcheck-mbps-head">
                                  Mbps Upload
                                </div>
                                <div class="intcheck-mbps-number">
                                  {{ uploadSpeed }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          v-else
                          class="intspd-cardcontainer d-flex flex-column align-items-center p-4 h-auto"
                        >
                          <div
                            class="intspd-main-text d-flex align-items-center justify-content-center"
                          >
                            <div
                              v-if="isNetSpeed"
                              class="d-flex align-items-center justify-content-center flex-column"
                            >
                              <img
                                src="../assets/newui-img/icstable.svg"
                                alt=""
                                class="my-1"
                              />
                              Your internet speed is stable.
                              <br />
                              Click "Next" to proceed with the test
                            </div>
                            <div
                              v-else
                              class="d-flex align-items-center justify-content-center flex-column"
                            >
                              <img
                                src="../assets/newui-img/warning.svg"
                                alt=""
                                class="my-1"
                              />
                              Due to a slow internet connection, the test
                              <br />
                              cannot be taken.
                            </div>
                          </div>
                          <div
                            class="intspd-speed-container d-flex flex-column align-items-center p-4 mt-3"
                          >
                            <div class="intspd-sub-text mb-3">
                              Your Internet Speed Is
                            </div>
                            <div class="d-flex">
                              <div class="mr-5">
                                <div class="intcheck-mbps-head">
                                  Mbps Download
                                </div>
                                <div class="intcheck-mbps-number">
                                  {{ downloadSpeed }}
                                </div>
                              </div>
                              <div>
                                <div class="intcheck-mbps-head">
                                  Mbps Upload
                                </div>
                                <div class="intcheck-mbps-number">
                                  {{ uploadSpeed }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            v-if="bothFetched"
                            class="mt-5 d-flex align-items-center justify-content-center bluebtns"
                          >
                            <b-button
                              v-if="isNetSpeed"
                              @click="next()"
                              class="w-100px"
                              variant="primary"
                              >Next</b-button
                            >
                            <b-button
                              @click="resetAllValues"
                              v-else
                              class="w-100px"
                              variant="primary"
                              >Retry</b-button
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
    </div>
    <div class="screenSizeMobile">
      <MobileInternetCheck v-if="AllowedMobAndLap" />
      <Block v-else />
    </div>
  </div>
</template>

<script>
import Block from "../components/mobileView/Block.vue";
import MobileInternetCheck from "../components/mobileView/MobileNewInternetCheck.vue";
import internet from "../mixins/internet";
export default {
  data() {
    return {
      property: "value",
    };
  },
  mixins: [internet],
  components: {
    MobileInternetCheck,
    Block,
  },
};
</script>
<style>
@media only screen and (max-width: 1024px) {
  .screenSizeLap {
    display: none;
  }
}
@media only screen and (min-width: 1024px) {
  .screenSizeMobile {
    display: none;
  }
}

.intspd-cardcontainer {
  border-radius: 14px;
  background: #fff;
  box-shadow: 0px 15px 30px 0px rgba(122, 122, 122, 0.2);
  width: 590px;
}

.intspd-main-text {
  /* Due to a slow internet connection, the test cannot be taken. */

  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-size: 22px;
  text-align: center;

  color: #636363;
}

.intspd-speed-container {
  border-radius: 14px;
  background: #f4f4f4;
  width: 95%;
}

.intspd-sub-text {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 18px;
  color: #333333;
  text-align: center;
}

.intspd-speed {
  color: #333;
  font-size: 16px;
  font-weight: 700;
}

.i-guage {
  border: 8.107px solid #f5eeee;
  border-bottom: none;
  border-top-left-radius: 100% 200%;

  border-top-right-radius: 100% 200%;
  width: 100%;
  max-width: 200px;
}

.i-guage__body {
  width: 100%;
  height: 0;
  padding-bottom: 50%;
  background: #b4b4b4;
  position: relative;
  border-top-left-radius: 100% 200%;
  border-top-right-radius: 100% 200%;
  overflow: hidden;
}

.i-guage__fill {
  background: #4c78ce;
  position: absolute;
  top: 100%;
  left: 0;
  width: inherit;
  height: 100%;

  transform-origin: center top;
  transform: rotate(0.5turn);
  transition: transform 0.2s ease-out;
}

.i-guage__cover {
  border: 8.107px solid #f5eeee;
  background: white;
  width: 94%;
  height: 187%;
  border-radius: 50%;
  position: absolute;
  top: 7%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 25%;
}

.speedometer-origin {
  transform-origin: 50% 70%;
  transition: 0.5s all linear;
}

.speedometer-txt {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  color: #696969;
}
</style>
